import { put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './action-types';
import { actionObject } from '../../utils/common';
import { getSession } from '../selectors';
import FetchService from '../../utils/fetchService';
import axios from 'axios';
import { GetNewUsersPayload, GetNewUsersSuccess, OnboardingCompletion } from './types';

function* newUsersData({ payload }: GetNewUsersPayload) {
	try {
		const { token } = yield select(getSession);
		const data: GetNewUsersSuccess = yield FetchService('/admin/tracking/new-users', 'POST', payload, token);

		yield put(actionObject(actionTypes.GET_NEW_USERS_ASYNC, data.response));
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			yield put(actionObject(actionTypes.NEW_USERS_ERROR, error.response?.data));
			// 👇️ error: AxiosError<any, any>
			console.log('error message: ', error);
		} else {
			console.log('unexpected error: ', error);
		}
	}
}

function* onboardingCompletionData({ payload }: GetNewUsersPayload) {
	try {
		const { token } = yield select(getSession);
		const data: GetNewUsersSuccess = yield FetchService('/admin/tracking/new-users-completed', 'POST', payload, token);

		const filteredData = Object.fromEntries(Object.entries(data.response).map(([key, value]) => [key, isNaN(value) ? '0' : value])) as OnboardingCompletion;

		yield put(actionObject(actionTypes.GET_ONBOARDING_COMPLETION_ASYNC, filteredData));
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			yield put(actionObject(actionTypes.NEW_USERS_ERROR, error.response?.data));
			// 👇️ error: AxiosError<any, any>
			console.log('error message: ', error);
		} else {
			console.log('unexpected error: ', error);
		}
	}
}

export function* watchGetNewUsersData() {
	yield takeLatest(actionTypes.GET_NEW_USERS as any, newUsersData);
}

export function* watchGetOnboardingCompletionData() {
	yield takeLatest(actionTypes.GET_ONBOARDING_COMPLETION as any, onboardingCompletionData);
}
