import React, { useMemo } from 'react';
import { Box, Card, CardContent, Typography, Divider, Skeleton, Grid } from '@mui/material';
import { OnboardingCompletion as OnboardingCompletionType } from '../../store/newUsers/types';

interface OnboardingCompletionProps {
	stats: OnboardingCompletionType;
	loading?: boolean;
}

const OnboardingCompletion: React.FC<OnboardingCompletionProps> = ({ stats, loading }) => {
	const statEntries = useMemo(
		() => [
			{ label: 'Total Registered', value: stats.total },
			{ label: 'Registered This Period', value: stats.totalQuery },
			{ label: 'Completed Onboarding', value: stats.totalCompleted },
			{ label: 'Completion Percentage', value: `${stats.percentageCompleted}%` },
		],
		[stats.total, stats.totalQuery, stats.totalCompleted, stats.percentageCompleted]
	);

	const detailedEntries = useMemo(
		() => [
			{ label: 'Users on Basic Information', percentage: stats.percentageBasicInformation, total: stats.totalBasicInformation },
			{ label: 'Users on Complete Profile', percentage: stats.percentageCompleteProfile, total: stats.totalCompleteProfile },
			{ label: 'Users on Conversation Helpers', percentage: stats.percentageConversationHelper, total: stats.totalConversationHelper },
			{ label: 'Users on Match Preferences', percentage: stats.percentageMatchPreference, total: stats.totalMatchPreference },
			{ label: 'Users on Select Plan', percentage: stats.percentageSelectPlan, total: stats.totalSelectPlan },
			{ label: 'Users on Chivy SafeDate', percentage: stats.percentageSmsProtect, total: stats.totalSmsProtect },
			{ label: 'Users on Verification ID', percentage: stats.percentageVerificationId, total: stats.totalVerificationId },
			{ label: 'Users with completed Onboarding', percentage: stats.percentageCompleted, total: stats.totalCompleted },
		],
		[
			stats.percentageBasicInformation,
			stats.totalBasicInformation,
			stats.percentageCompleteProfile,
			stats.totalCompleteProfile,
			stats.percentageConversationHelper,
			stats.totalConversationHelper,
			stats.percentageMatchPreference,
			stats.totalMatchPreference,
			stats.percentageSelectPlan,
			stats.totalSelectPlan,
			stats.percentageSmsProtect,
			stats.totalSmsProtect,
			stats.percentageVerificationId,
			stats.totalVerificationId,
			stats.percentageCompleted,
			stats.totalCompleted,
		]
	);

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '200px' }}>
			<Card sx={{ width: '100%', padding: '20px', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
				<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Typography variant="h6" component="div" color="primary" gutterBottom textTransform="uppercase">
						Onboarding Metrics
					</Typography>
					<Divider sx={{ width: '100%', marginBottom: '20px' }} />
					<Grid container spacing={2}>
						{loading
							? Array.from({ length: statEntries.length }).map((_, index) => (
									<Grid item xs={6} key={index}>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
											<Skeleton variant="text" width={80} height={30} />
											<Skeleton variant="text" width={100} height={20} />
										</Box>
									</Grid>
							  ))
							: statEntries.map((entry, index) => (
									<Grid item xs={6} key={index}>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
											<Typography variant="h6" component="div">
												{entry.value}
											</Typography>
											<Typography variant="body2" color="textSecondary">
												{entry.label}
											</Typography>
										</Box>
									</Grid>
							  ))}
					</Grid>
					<Divider sx={{ width: '100%', marginY: '20px' }} />
					<Typography variant="h6" component="div" color="primary" gutterBottom margin={'0 0 20px 0'}>
						Detailed Breakdown
					</Typography>
					<Grid container spacing={2}>
						{loading
							? Array.from({ length: detailedEntries.length }).map((_, index) => (
									<Grid item xs={12} sm={6} key={index}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<Skeleton variant="text" width="50%" height={20} />
											<Skeleton variant="text" width="25%" height={20} />
											<Skeleton variant="text" width="25%" height={20} />
										</Box>
									</Grid>
							  ))
							: detailedEntries.map((entry, index) => (
									<Grid item xs={12} sm={6} key={index}>
										<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
											<Typography variant="body2" color="textSecondary" sx={{ width: '50%' }}>
												{entry.label}
											</Typography>
											<Typography variant="body2" sx={{ width: '25%', textAlign: 'right' }}>
												{entry.percentage}%
											</Typography>
											<Typography variant="body2" color="textSecondary" sx={{ width: '25%', textAlign: 'right' }}>
												{entry.total}
											</Typography>
										</Box>
									</Grid>
							  ))}
					</Grid>
				</CardContent>
			</Card>
		</Box>
	);
};

export default OnboardingCompletion;
