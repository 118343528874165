import { SessionState } from './types';
import * as actionTypes from './action-types';
import { ReducerPayload } from '../../utils/types';

const initialState: SessionState = {
	expiredToken: false,
	username: undefined,
	token: undefined,
	error: undefined,
};

const Session = (state = initialState, { type, payload }: ReducerPayload<typeof actionTypes>): SessionState => {
	switch (type) {
		case actionTypes.LOGIN_SUCCESS:
			return { ...state, ...payload };
		case actionTypes.LOGIN_ERROR:
			return { ...state, error: payload };
		case actionTypes.EXPIRED_TOKEN:
			return { ...state, expiredToken: true };
		case actionTypes.LOGOUT_SUCCESS:
			return initialState;
		default:
			return state;
	}
};

export default Session;
