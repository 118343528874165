import React from 'react';
import { Box, Card, CardContent, Typography, Divider, Skeleton, Grid } from '@mui/material';

interface StatisticsCardProps {
	stats: {
		total: number;
		percentage: string;
	};
	loading?: boolean;
}

const StatisticsCard: React.FC<StatisticsCardProps> = ({ stats, loading }) => {
	const statEntries = [
		{ label: 'Total Registered Users This Period', value: stats.total },
		{ label: 'Rate of the Total Amount of Users', value: `${stats.percentage}%` },
	];

	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', minHeight: '200px', scrollSnapMarginBottom: '20px' }}>
			<Card sx={{ width: '100%', padding: '5px', borderRadius: '10px', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.2)' }}>
				<CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					{loading ? (
						<>
							<Skeleton variant="text" width={200} height={30} sx={{ marginBottom: '20px' }} />
							<Divider sx={{ width: '100%', marginBottom: '20px' }} />
							{Array.from({ length: statEntries.length }).map((_, index) => (
								<Box key={index} sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
									<Skeleton variant="text" width="45%" height={20} />
									<Skeleton variant="text" width="45%" height={20} />
								</Box>
							))}
						</>
					) : (
						<>
							<Typography variant="h6" component="div" color="primary" gutterBottom textTransform="uppercase">
								Statistics
							</Typography>
							<Divider sx={{ width: '100%', marginBottom: '20px' }} />
							<Grid container spacing={2}>
								{statEntries.map((entry, index) => (
									<Grid item xs={6} key={index}>
										<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
											<Typography variant="h6" component="div">
												{entry.value}
											</Typography>
											<Typography variant="body2" color="textSecondary">
												{entry.label}
											</Typography>
										</Box>
									</Grid>
								))}
							</Grid>
						</>
					)}
				</CardContent>
			</Card>
		</Box>
	);
};

export default StatisticsCard;
