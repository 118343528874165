import { NewUsersState } from './types';
import * as actionTypes from './action-types';
import { ReducerPayload } from '../../utils/types';

const initialState: NewUsersState = {
	data: {
		total: 0,
		percentage: '',
		users: [],
		onboardingCompletion: {
			total: 0,
			totalQuery: 0,
			percentageBasicInformation: '',
			percentageCompleteProfile: '',
			percentageCompleted: '',
			percentageConversationHelper: '',
			percentageMatchPreference: '',
			percentageSelectPlan: '',
			percentageSmsProtect: '',
			percentageVerificationId: '',
			totalBasicInformation: 0,
			totalCompleteProfile: 0,
			totalCompleted: 0,
			totalConversationHelper: 0,
			totalMatchPreference: 0,
			totalSelectPlan: 0,
			totalSmsProtect: 0,
			totalVerificationId: 0,
		},
	},
	error: undefined,
	loading: false,
};

const NewUsers = (state = initialState, { type, payload }: ReducerPayload<typeof actionTypes>): NewUsersState => {
	switch (type) {
		case actionTypes.GET_NEW_USERS:
			return { ...state, loading: true };
		case actionTypes.GET_NEW_USERS_ASYNC:
			return { ...state, data: { ...state.data, ...payload }, loading: false };
		case actionTypes.GET_ONBOARDING_COMPLETION:
			return { ...state, loading: true };
		case actionTypes.GET_ONBOARDING_COMPLETION_ASYNC:
			return { ...state, data: { ...state.data, onboardingCompletion: payload }, loading: false };
		case actionTypes.NEW_USERS_ERROR:
			return { ...state, error: payload, loading: false };
		case actionTypes.LOGOUT_SUCCESS:
			return initialState;
		default:
			return state;
	}
};

export default NewUsers;
