import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, Container, FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { loginInit } from '../../store/actions';
import { AppDispatch } from '../../store/types';
interface LoginInput {
	username: string;
	pass: string;
}

const schema = yup.object().shape({
	username: yup.string().min(4, '* Username must have at least 4 digits').required('Please enter your username'),
	pass: yup.string().min(10, '* The password must contain at least 10 characters').required('Please enter your password'),
});

function LoginCard() {
	const dispatch: AppDispatch = useDispatch();
	const [loginError, setLoginError] = useState(false);
	const {
		formState: { errors },
		handleSubmit,
		control,
	} = useForm<LoginInput>({ resolver: yupResolver(schema) });
	const onSubmit: SubmitHandler<LoginInput> = data => {
		dispatch(loginInit(data));
	};
	const [showPassword, setShowPassword] = useState(false);
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};

	return (
		<>
			<Container>
				<Box>
					<Stack direction="column" justifyContent={'center'} spacing={2}>
						<h3 style={{ textAlign: 'center' }}>Administrative website for Chivy</h3>
						<FormControl fullWidth sx={{ m: 1 }}>
							<Controller
								name="username"
								control={control}
								render={({ field: { onChange, onBlur, value } }) => (
									<TextField
										id="outlined-basic-username"
										label="Username"
										variant="outlined"
										error={Boolean(errors.username)}
										helperText={errors.username ? errors.username.message : null}
										onChange={event => {
											if (loginError) {
												setLoginError(false);
											}
											onChange(event);
										}} // send value to hook form
										onBlur={onBlur} // notify when input is touched/blur
										value={value}
									/>
								)}
							/>
						</FormControl>

						<Controller
							name="pass"
							control={control}
							render={({ field: { onChange, onBlur, value } }) => (
								<FormControl fullWidth sx={{ m: 1 }}>
									<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
									<OutlinedInput
										id="outlined-adornment-password"
										type={showPassword ? 'text' : 'password'}
										error={Boolean(errors.pass)}
										onChange={event => {
											if (loginError) {
												setLoginError(false);
											}
											onChange(event);
										}} // send value to hook form
										onBlur={onBlur} // notify when input is touched/blur
										value={value}
										endAdornment={
											<InputAdornment position="end">
												<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
													{showPassword ? <VisibilityOff /> : <Visibility />}
												</IconButton>
											</InputAdornment>
										}
										label="Password"
									/>
									{Boolean(errors.pass) && (
										<FormHelperText error id="password-error">
											* The password must contain at least 10 characters
										</FormHelperText>
									)}
								</FormControl>
							)}
						/>

						<Button onClick={handleSubmit(onSubmit)} size="medium" variant="contained" style={{ height: '56px' }}>
							Log in
						</Button>
					</Stack>
				</Box>
			</Container>
		</>
	);
}

export default LoginCard;
