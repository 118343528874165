import { createTheme } from '@mui/material';

export const Theme = createTheme({
	palette: {
		primary: {
			main: '#000000',
		},
		secondary: {
			main: '#fff',
			light: '#e8e8e8',
		},
		error: {
			main: '#E33F3B',
		},
		info: {
			main: '#000',
		},
	},
});
