import { UsersState } from './types';
import * as actionTypes from './action-types';
import { ReducerPayload } from '../../utils/types';

const initialState: UsersState = {
	users: undefined,
	error: undefined,
};

const Users = (state = initialState, { type, payload }: ReducerPayload<typeof actionTypes>): UsersState => {
	switch (type) {
		case actionTypes.GET_USERS_ASYNC:
			return { ...state, users: payload };
		case actionTypes.LOGOUT_SUCCESS:
			return initialState;
		default:
			return state;
	}
};

export default Users;
