import React from 'react';
import CustomAppBar from '../../Components/AppBar/CustomAppBar';
import { Box, Container } from '@mui/material';

const Layout: React.FC = ({ children }) => {
	return (
		<Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
			<CustomAppBar />
			<Box sx={{ flex: 1, overflowY: 'auto' }}>
				<Container sx={{ height: '100%' }}>{children}</Container>
			</Box>
		</Box>
	);
};

export default Layout;
