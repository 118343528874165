import React, { useEffect, useMemo, useState } from 'react';
import GenericTable from '../../Components/CustomTable';
import Layout from '../../Components/Layout';
import { AppDispatch } from '../../store/types';
import { useDispatch, useSelector } from 'react-redux';
import { getNewUsers } from '../../store/selectors';
import { getNewUsersData, getOboardingCompletionData } from '../../store/actions';
import { Box, Tabs, Tab } from '@mui/material';
import { removeField } from '../../utils/common';
import { Column } from '../../Components/CustomTable/types';
import DateRangeFilter from '../../Components/DateRangeFilter/DateRangeFilter';
import { endOfYear, format, startOfYear } from 'date-fns';
import HeaderCard from '../../Components/HeaderCard';
import OnboardingCompletion from '../../Components/OnboardingCompletion';
import StatisticsCard from '../../Components/StatisticsCard';

const App: React.FC = () => {
	const dispatch: AppDispatch = useDispatch();
	const newUsers = useSelector(getNewUsers);
	const [tabIndex, setTabIndex] = useState(0);
	const { loading, data: newUsersData } = newUsers;

	useEffect(() => {
		const startDate = format(startOfYear(new Date()), 'yyyy-MM-dd');
		const endDate = format(endOfYear(new Date()), 'yyyy-MM-dd');
		dispatch(getNewUsersData({ startDate, endDate }));
		dispatch(getOboardingCompletionData({ startDate, endDate }));
	}, [dispatch]);

	const data = useMemo(() => {
		return removeField(newUsers.data.users, '_id');
	}, [newUsers.data.users]);

	const onDateRangeChange = (startDate: string, endDate: string) => {
		dispatch(getNewUsersData({ startDate, endDate }));
		dispatch(getOboardingCompletionData({ startDate, endDate }));
	};

	const handleTabChange = (_: React.ChangeEvent<{}>, newValue: number) => {
		setTabIndex(newValue);
	};

	return (
		<Layout>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					minHeight: '100%',
					overflowY: 'auto',
					marginTop: '50px',
					paddingBottom: '50px',
				}}>
				<HeaderCard title="Users Metrics" />
				<DateRangeFilter onDateRangeChange={onDateRangeChange} />
				<Box sx={{ width: '100%' }}>
					<Tabs value={tabIndex} onChange={handleTabChange} centered>
						<Tab label="Onboarding Completion" />
						<Tab label="New Users" />
					</Tabs>
					{tabIndex === 0 && (
						<Box sx={{ marginTop: '20px' }}>
							<OnboardingCompletion stats={newUsersData.onboardingCompletion} loading={loading} />
						</Box>
					)}
					{tabIndex === 1 && (
						<Box sx={{ width: '100%' }}>
							<StatisticsCard stats={{ total: newUsersData.total, percentage: newUsersData.percentage }} loading={loading} />
							<GenericTable columns={columns} data={data} loading={loading} />
						</Box>
					)}
				</Box>
			</Box>
		</Layout>
	);
};

export default App;

const columns: Column[] = [
	{ id: 'firstName', label: 'Name', width: '20%' },
	{ id: 'lastName', label: 'Last Name', width: '20%' },
	{ id: 'typeRegister', label: 'Registered from', contentAligment: 'center', headerAlignment: 'center', width: '15%' },
	{ id: 'email', label: 'Email', contentAligment: 'right', headerAlignment: 'center', width: '25%' },
	{ id: 'processOnboarding', label: 'Onboarding Status', contentAligment: 'center', headerAlignment: 'center', width: '20%' },
];
