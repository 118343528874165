import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getSession } from '../store/selectors';
import { actionObject } from '../utils/common';
import { LOGOUT_SUCCESS } from '../store/session/action-types';
import Login from '../Screens/Login/Login';
import ErrorPage from '../Screens/ErrorPage/ErrorPage';
import Home from '../Screens/Home';
import NewUsers from '../Screens/NewUsers/NewUsers';

let timeout: NodeJS.Timeout | null = null;

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const session = useSelector(getSession);
	if (!session.token) {
		return <Navigate to="/" />;
	}
	return <>{children}</>;
};

const AppRoutes: React.FC = () => {
	const session = useSelector(getSession);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (session.expiredToken) {
			if (timeout) {
				clearTimeout(timeout);
			}
			timeout = setTimeout(() => {
				dispatch(actionObject(LOGOUT_SUCCESS));
				navigate('/');
			}, 500);
		}
	}, [session.expiredToken, dispatch, navigate]);

	return (
		<Routes>
			<Route path="/" element={session.token ? <Navigate to="/new-users" /> : <Login />} />
			<Route path="*" element={<ErrorPage />} />
			<Route
				path="/home"
				element={
					<ProtectedRoute>
						<Home />
					</ProtectedRoute>
				}
			/>
			<Route
				path="/new-users"
				element={
					<ProtectedRoute>
						<NewUsers />
					</ProtectedRoute>
				}
			/>
		</Routes>
	);
};

const Navigation: React.FC = () => {
	return (
		<Router>
			<AppRoutes />
		</Router>
	);
};

export default Navigation;
