import axios from 'axios';
import { put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './action-types';
import { LoginParams, AxiosLoginSuccess, LogoutUserResponse, AxiosLogoutSuccess } from './types';
import { actionObject } from '../../utils/common';
import { getSession } from '../selectors';
import FetchService from '../../utils/fetchService';

function* LoginUser({ payload }: LoginParams) {
	try {
		const request = {
			username: payload.username,
			password: payload.pass,
		};

		const data: AxiosLoginSuccess = yield FetchService('/admin/auth/login', 'POST', request);
		if (data.code === 200) {
			const save = {
				username: data.response.username,
				token: data.response.token,
			};
			yield put(actionObject(actionTypes.LOGIN_ERROR, undefined));
			yield put(actionObject(actionTypes.LOGIN_SUCCESS, save));
		}

		return;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			yield put(actionObject(actionTypes.LOGIN_ERROR, error.response?.data));
			// 👇️ error: AxiosError<any, any>
			return error.message;
		} else {
			console.log('unexpected error: ', error);
			return 'An unexpected error occurred';
		}
	}
}

function* LogoutUser() {
	try {
		const { token } = yield select(getSession);
		const data: AxiosLogoutSuccess = yield FetchService('/admin/auth/logout', 'DELETE', undefined, token);
		yield put(actionObject(actionTypes.LOGOUT_SUCCESS, ''));

		return data;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			// 👇️ error: AxiosError<any, any>
			return error.message;
		} else {
			console.log('unexpected error: ', error);
			return 'An unexpected error occurred';
		}
	}
}

export function* watchLogin() {
	yield takeLatest(actionTypes.LOGIN_INIT as any, LoginUser);
}

export function* watchLogout() {
	yield takeLatest(actionTypes.LOGOUT_INIT as any, LogoutUser);
}
