import React from 'react';
import { Box, Card, Typography } from '@mui/material';

interface HeaderCardProps {
	title: string;
}

const HeaderCard: React.FC<HeaderCardProps> = ({ title }) => {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
			<Card
				sx={{
					width: '100%',
					maxWidth: '600px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					justifyContent: 'center',
					p: 2,
					minHeight: '80px',
				}}>
				<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
					<img style={{ width: '200px', marginBottom: '10px', marginTop: '10px' }} src={require('../../assets/chivy-logo.png')} alt="Logo" />
					<Typography gutterBottom variant="h6" component="div" color="black" textAlign={'center'} textTransform="uppercase">
						{title}
					</Typography>
				</Box>
			</Card>
		</Box>
	);
};

export default HeaderCard;
