import { combineReducers } from 'redux';
import { StoreType } from './types';
import Session from './session/reducer';
import Users from './users/reducer';
import NewUsers from './newUsers/reducer';

const reducers = combineReducers<StoreType>({
	Session,
	Users,
	NewUsers,
});

export default reducers;
