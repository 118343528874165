import React, { useState } from 'react';
import { AppBar, Box, IconButton, Toolbar, Drawer, List, ListItemIcon, ListItemText, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeIcon from '@mui/icons-material/Home';
import { AppDispatch } from '../../store/types';
import { useDispatch } from 'react-redux';
import { logoutInit } from '../../store/actions';
import { useNavigate } from 'react-router-dom';

const CustomAppBar = () => {
	const dispatch: AppDispatch = useDispatch();
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigation = useNavigate();

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
			return;
		}
		setDrawerOpen(open);
	};

	const handleLogout = () => {
		navigation('/');
		dispatch(logoutInit(''));
	};

	const goTo = (section: string) => navigation(section);

	const menuItems = [
		{ text: 'Home', icon: <HomeIcon />, onClick: () => goTo('/home') },
		{ text: 'Users Metrics', icon: <AccountCircleIcon />, onClick: () => goTo('/new-users') },
		{ text: 'Logout', icon: <ExitToAppIcon />, onClick: handleLogout },
	];

	return (
		<AppBar position="sticky" sx={{ width: '100%', overflowX: 'hidden' }}>
			<Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
					<MenuIcon />
				</IconButton>
				<Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} PaperProps={{ sx: { backgroundColor: '#000', color: '#fff' } }}>
					<Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
						<List>
							{menuItems.map((item, index) => (
								<ListItemButton key={index} onClick={item.onClick}>
									<ListItemIcon sx={{ color: '#fff' }}>{item.icon}</ListItemIcon>
									<ListItemText primary={item.text} />
								</ListItemButton>
							))}
						</List>
					</Box>
				</Drawer>
				<Box sx={{ display: 'flex', alignItems: 'center' }}>
					<img style={{ width: '130px' }} src={require('../../assets/chivy-logo-white.png')} alt="Logo" />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export default CustomAppBar;
