import { put, takeLatest, select } from 'redux-saga/effects';
import * as actionTypes from './action-types';
import { actionObject } from '../../utils/common';
import { getSession, getUsers } from '../selectors';
import FetchService from '../../utils/fetchService';
import axios from 'axios';
import { GetUsersSuccess, User, ChangeStateSuccess } from './types';

function* UserData() {
	try {
		const { token } = yield select(getSession);
		const data: GetUsersSuccess = yield FetchService('/admin/user', 'GET', undefined, token);
		yield put(actionObject(actionTypes.GET_USERS_ASYNC, data.response));

		return data;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			yield put(actionObject(actionTypes.USERS_ERROR, error.response?.data));
			// 👇️ error: AxiosError<any, any>
			return error.message;
		} else {
			console.log('unexpected error: ', error);
			return 'An unexpected error occurred';
		}
	}
}

function* changeStateAsync({ payload }: any) {
	try {
		const { token } = yield select(getSession);
		const { users } = yield select(getUsers);
		const found = users.find((element: User) => element.email === payload.email);

		const data: ChangeStateSuccess = yield FetchService(`/admin/user/change-state?userId=${found.id}`, 'PUT', undefined, token);
		const newUserData = users.map((element: User) => {
			if (element.email === payload.email) {
				const newData = {
					id: element.id,
					firstName: element.firstName,
					lastName: element.lastName,
					email: element.email,
					phone: element.phone,
					typeRegister: element.typeRegister,
					state: {
						id: payload.state === 'active' ? 2 : 1,
						name: payload.state === 'active' ? 'blocked' : 'active',
					},
				};
				return newData;
			} else {
				return element;
			}
		});
		yield put(actionObject(actionTypes.GET_USERS_ASYNC, newUserData));
		return data;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			yield put(actionObject(actionTypes.USERS_ERROR, error.response?.data));
			// 👇️ error: AxiosError<any, any>
			return error.message;
		} else {
			console.log('unexpected error: ', error);
			return 'An unexpected error occurred';
		}
	}
}

function* resetPasswordAsync({ payload }: any) {
	try {
		const { token } = yield select(getSession);
		const { users } = yield select(getUsers);
		const found = users.find((element: User) => element.email === payload.email);

		const data: ChangeStateSuccess = yield FetchService(`/admin/user/reset-password?userId=${found.id}`, 'PUT', undefined, token);
		return data;
	} catch (error: any) {
		if (axios.isAxiosError(error)) {
			console.log('error message: ', error.response?.data);
			yield put(actionObject(actionTypes.USERS_ERROR, error.response?.data));
			// 👇️ error: AxiosError<any, any>
			return error.message;
		} else {
			console.log('unexpected error: ', error);
			return 'An unexpected error occurred';
		}
	}
}

export function* watchUserData() {
	yield takeLatest(actionTypes.GET_USERS as any, UserData);
}

export function* watchChangeState() {
	yield takeLatest(actionTypes.STATE_CHANGE as any, changeStateAsync);
}

export function* watchResetPassword() {
	yield takeLatest(actionTypes.RESET_PASS as any, resetPasswordAsync);
}
