import React, { useState } from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import DatePicker from 'react-datepicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, startOfYear, endOfYear } from 'date-fns';
import './index.css';

enum DateRangeFilterTypes {
	Daily = 'Daily',
	Weekly = 'Weekly',
	Monthly = 'Monthly',
	Quarterly = 'Quarterly',
	Annually = 'Annually',
}

interface DateRangeFilterProps {
	onDateRangeChange: (startDate: string, endDate: string) => void;
}

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({ onDateRangeChange }) => {
	const [value, setValue] = useState<Date>(new Date());
	const [rangeType, setRangeType] = useState<DateRangeFilterTypes>(DateRangeFilterTypes.Annually);
	const [showCalendar, setShowCalendar] = useState<boolean>(false);
	const [selectedDateText, setSelectedDateText] = useState<string>(`Year: ${format(new Date(), 'yyyy')}`);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleRangeTypeChange = (newRangeType: DateRangeFilterTypes) => {
		setRangeType(newRangeType);
		setShowCalendar(true);
		setAnchorEl(null);
	};

	const handleCalendarChange = (date: Date) => {
		setValue(date);
		updateDateRange(rangeType, date);
		setShowCalendar(false);
	};

	const updateDateRange = (range: DateRangeFilterTypes, date: Date) => {
		const dateFormat = 'yyyy-MM-dd';
		const dates = {
			startDate: '',
			endDate: '',
			dateText: '',
		};

		switch (range) {
			case DateRangeFilterTypes.Weekly:
				dates.startDate = format(startOfWeek(date), dateFormat);
				dates.endDate = format(endOfWeek(date), dateFormat);
				dates.dateText = `Week: ${format(startOfWeek(date), 'dd MMM yyyy')} to ${format(endOfWeek(date), 'dd MMM yyyy')}`;
				break;
			case DateRangeFilterTypes.Monthly:
				dates.startDate = format(startOfMonth(date), dateFormat);
				dates.endDate = format(endOfMonth(date), dateFormat);
				dates.dateText = `Month: ${format(date, 'MMMM yyyy')}`;
				break;
			case DateRangeFilterTypes.Quarterly:
				dates.startDate = format(startOfQuarter(date), dateFormat);
				dates.endDate = format(endOfQuarter(date), dateFormat);
				dates.dateText = `Quarter: from ${format(startOfQuarter(date), 'MMM yyyy')} to ${format(endOfQuarter(date), 'MMM yyyy')}`;
				break;
			case DateRangeFilterTypes.Annually:
				dates.startDate = format(startOfYear(date), dateFormat);
				dates.endDate = format(endOfYear(date), dateFormat);
				dates.dateText = `Year: ${format(date, 'yyyy')}`;
				break;
			case DateRangeFilterTypes.Daily:
			default:
				dates.startDate = format(date, dateFormat);
				dates.endDate = format(date, dateFormat);
				dates.dateText = `Day: ${format(date, 'dd MMM yyyy')}`;
				break;
		}

		onDateRangeChange(dates.startDate, dates.endDate);
		setSelectedDateText(dates.dateText);
	};

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box sx={{ marginTop: '20px', marginBottom: '10px', width: '100%' }}>
			<Box sx={{ position: 'relative', width: '100%', textAlign: 'right' }}>
				<Typography sx={{ color: 'black', fontSize: '16px', fontWeight: 'bold' }}>{selectedDateText}</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px', width: '100%' }}>
					<Button
						aria-controls="simple-menu"
						aria-haspopup="true"
						onClick={handleClick}
						sx={{
							color: 'white',
							backgroundColor: 'black',
							'&:hover': {
								backgroundColor: 'rgba(0, 0, 0, 0.8)',
							},
							textTransform: 'none',
							width: '200px',
						}}>
						{`Filter by ${rangeType}`}
						<CalendarMonthIcon fontSize="small" />
					</Button>
					<Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} sx={{ width: '230px' }}>
						{Object.keys(DateRangeFilterTypes).map(key => (
							<MenuItem key={key} onClick={() => handleRangeTypeChange(DateRangeFilterTypes[key as keyof typeof DateRangeFilterTypes])} sx={{ width: '230px' }}>
								{DateRangeFilterTypes[key as keyof typeof DateRangeFilterTypes]}
							</MenuItem>
						))}
					</Menu>
				</Box>
				{showCalendar && (
					<Box sx={{ position: 'absolute', top: 'calc(100% + 5px)', zIndex: 3, width: '100%' }}>
						<DatePicker
							selected={value}
							onChange={handleCalendarChange}
							showWeekNumbers={rangeType === DateRangeFilterTypes.Weekly}
							showMonthYearPicker={rangeType === DateRangeFilterTypes.Monthly}
							showQuarterYearPicker={rangeType === DateRangeFilterTypes.Quarterly}
							showYearPicker={rangeType === DateRangeFilterTypes.Annually}
							dateFormat={
								rangeType === DateRangeFilterTypes.Weekly ? "wo 'week of' yyyy" : rangeType === DateRangeFilterTypes.Monthly ? 'MM yyyy' : 'MMMM yyyy'
							}
							showPopperArrow={false}
							inline
							calendarClassName="custom-calendar"
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default DateRangeFilter;
