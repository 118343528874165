import React, { useEffect, useState } from 'react';
import { Box, Container, Stack, Snackbar } from '@mui/material';
import { LoginCard } from '../../Components';
import { useSelector } from 'react-redux';
import { getSession } from '../../store/selectors';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Login() {
	const session = useSelector(getSession);
	const [loginError, setLoginError] = useState(false);
	useEffect(() => {
		if (session.error) {
			setLoginError(true);
		}
	}, [session.error]);

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setLoginError(false);
	};
	return (
		<Container
			maxWidth="xl"
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
				width: {
					xs: '100%',
					sm: '80%',
					md: '60%',
					lg: '50%',
					xl: '40%',
				},
			}}>
			<Box width="100%">
				<Stack justifyContent="center" direction="column" spacing={2}>
					<Box display="flex" alignItems="center" justifyContent="center" height="100%">
						<img src={require('../../assets/chivy-logo.png')} alt="Chivy Logo" style={{ maxWidth: '100%', width: '300px', height: 'auto' }} />
					</Box>
					<LoginCard />
				</Stack>
			</Box>
			<Snackbar open={loginError} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
				<Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
					{session.error?.message}
				</Alert>
			</Snackbar>
		</Container>
	);
}

export default Login;
