import React from 'react';
import Navigation from './Navigation/Navigation';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { Provider } from 'react-redux';

function App() {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor} loading={null}>
				<Navigation />
			</PersistGate>
		</Provider>
	);
}

export default App;
