import axios, { AxiosRequestConfig, Method } from 'axios';
import { store } from '../store';
import { expiredToken } from '../store/actions';

const TIMEOUT = 30000;
const HOST_URL = process.env.REACT_APP_API;

const FetchService = async (url: string, method: Method = 'GET', params: any = undefined, auth: string = '', consoleLog = false) => {
	const objectRequest: AxiosRequestConfig = {
		method,
		url: `${HOST_URL}${url}`,
		data: params,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
		timeout: TIMEOUT,
	};

	if (consoleLog) {
		console.log('object Request', objectRequest);
	}

	if (auth && objectRequest.headers) {
		objectRequest.headers.Authorization = auth;
	}
	if (consoleLog) {
		console.log('auth', auth);
	}

	let response;
	try {
		response = await axios(objectRequest);
	} catch (error: any) {
		if (error.response.data.code === 401) {
			store.dispatch(expiredToken());
		}
		throw error;
	}

	if (consoleLog) {
		console.log('response', response);
	}
	const responseBody = response.data;
	if (consoleLog) {
		console.log('responseBody', responseBody);
	}
	return responseBody;
};

export default FetchService;
