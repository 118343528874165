import React from 'react';
import Layout from '../../Components/Layout';
import { Box } from '@mui/material';

const Home: React.FC = () => {
	return (
		<Layout>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100%',
				}}>
				<img src={require('../../assets/chivy-logo.png')} alt="Chivy Logo" style={{ maxWidth: '100%', width: '600px', height: 'auto' }} />
			</Box>
		</Layout>
	);
};

export default Home;
