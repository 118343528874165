import React from 'react';
import { createTheme, ThemeProvider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Typography, Skeleton } from '@mui/material';
import { GenericTableProps } from './types';

const theme = createTheme({
	palette: {
		mode: 'dark',
		background: {
			default: '#121212',
			paper: '#1e1e1e',
		},
		text: {
			primary: '#fff',
		},
	},
});

const GenericTable: React.FC<GenericTableProps> = ({ columns, data, title, loading }) => {
	return (
		<ThemeProvider theme={theme}>
			<Box>
				{title && (
					<Typography variant="h6" component="div" sx={{ marginBottom: '20px', color: 'text.primary', fontWeight: 'bold', textTransform: 'uppercase' }}>
						{title}
					</Typography>
				)}
				<TableContainer component={Paper} sx={{ maxHeight: '40vh', overflow: 'auto', maxWidth: '100%' }}>
					<Table stickyHeader sx={{ maxWidth: '100%' }}>
						<TableHead>
							<TableRow>
								{columns.map((column, index) => (
									<TableCell
										key={index}
										align={column.headerAlignment}
										width={column.width}
										sx={{ backgroundColor: '#333', color: '#fff', fontWeight: 'bold' }}>
										{loading ? <Skeleton variant="text" width="100%" sx={{ bgcolor: 'grey.700' }} /> : column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{loading ? (
								Array.from(new Array(7)).map((_, rowIndex) => (
									<TableRow key={rowIndex}>
										{columns.map((column, colIndex) => (
											<TableCell key={colIndex} sx={{ bgcolor: 'grey.800' }} width={column.width}>
												<Skeleton variant="text" width="100%" sx={{ bgcolor: 'grey.700' }} />
											</TableCell>
										))}
									</TableRow>
								))
							) : data.length > 0 ? (
								data.map((row, rowIndex) => (
									<TableRow hover key={rowIndex} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#2a2a2a' } }}>
										{columns.map((column, colIndex) => (
											<TableCell key={colIndex} align={column.contentAligment} sx={{ color: '#fff' }}>
												{row[column.id]}
											</TableCell>
										))}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell colSpan={columns.length} sx={{ textAlign: 'center', color: 'grey.500' }}>
										No data available
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</ThemeProvider>
	);
};

export default GenericTable;
