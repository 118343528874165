import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from '@redux-saga/core';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import reducers from './reducers';
import rootSaga from './sagas';
import { configureStore } from '@reduxjs/toolkit';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['Session'],
	blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({ reducer: persistedReducer, middleware: [sagaMiddleware] });
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
